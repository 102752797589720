
















































































































































































































































































































































import {Component, Prop, Watch} from 'vue-property-decorator';
import InnerChatsList from '@/components/InnerChatsList.vue';
import SearchView from '@/components/SearchView.vue';
import {archiveStore} from '@/store/modules/archive/ArchiveStore';
import {copyTextToClipboard, unsubscribeSafe, dateToMDY, getFirstName} from '@/utils/helpers';
import ArchiveCaseListItem from '@/components/ArchiveCaseListItem.vue';
import ImageMessage from '@/components/messages/ImageMessage.vue';
import StandardMessage from '@/components/messages/StandardMessage.vue';
import RequestPersonalDataMessage from '@/components/messages/RequestPersonalDataMessage.vue';
import PersonAddedToChatMessage from '@/components/messages/PersonAddedToChatMessage.vue';
import ReplyMessage from '@/components/messages/ReplyMessage.vue';
import PersonalDataResultMessage from '@/components/messages/PersonalDataResultMessage.vue';
import LocationMessage from '@/components/messages/LocationMessage.vue';
import BusinessCardMessage from '@/components/messages/BusinessCardMessage.vue';
import SystemMessage from '@/components/messages/SystemMessage.vue';
import AvatarWithStatus from '@/components/AvatarWithStatus.vue';
import GroupAvatar from '@/components/GroupAvatar.vue';
import ChatInfo from '@/components/ChatInfo.vue';
import Notifications from '@/components/mixins/Notifications';
import {mixins} from 'vue-class-component';
import axios from '@/plugins/axios';
import {profileStore} from '@/store/modules/profile';
import {Unsubscribe} from 'firebase/firestore';
import {chatStore} from '@/store/modules/chat/ChatStore';
import EmptyStage from '@/components/EmptyState.vue';
import ToolTip from '@/components/custom/ToolTip.vue'

Component.registerHooks(['beforeRouteEnter', 'beforeRouteUpdate', 'beforeRouteLeave'])

@Component({
  name: 'archive',
  components: {
    ChatInfo,
    GroupAvatar,
    AvatarWithStatus,
    SystemMessage,
    BusinessCardMessage,
    LocationMessage,
    PersonalDataResultMessage,
    ReplyMessage,
    PersonAddedToChatMessage,
    RequestPersonalDataMessage,
    StandardMessage, ImageMessage, ArchiveCaseListItem, SearchView, InnerChatsList, EmptyStage, ToolTip
  }
})
export default class Archive extends mixins(Notifications) {
  @Prop() archiveId?: string
  @Prop() type?: string;
  @Prop() subtype?: string;

  showError: boolean = false;
  timeout: number = 3000;

  exportMode: boolean = false
  srcArchive: any[] = []
  dialogExportResult: boolean = false
  drawerChatInfo: boolean = false
  chatInfoWindowKey: string = ''
  unsubscribeCaseNotes: Unsubscribe | null = null;
  unsubscribeCustomerNotes: Unsubscribe | null = null;
  emptyIcon: string = ''
  emptyTitle: string = ''
  emptyDescription: string = ''

  userId?: string | null = profileStore.t2bUser?.id

  predicatePersonal = (archive) => archive.memberIDs?.includes(this.userId);
  predicateAll = (archive) => !archive.memberIDs?.includes(this.userId);
    
  disableExport: boolean = true
  dialogArchive: boolean = false
  dialogCalendar: boolean = false

  exportType: number = 0;
  exportFileType: number = 0;
  exportFileTypes: any[] = [
    {name: '.pdf', value: 0},
    {name: '.html', value: 1}
  ];  
  
  toEmail: string = ''
  ccEmail: string = ''
  
  startDate: Date = new Date()
  endDate:  Date = new Date()
  dates: any[] = [(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substring(0, 10)]
  daysOfWeek: string[] = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']
  selectedDate: string = '' 

  get isAll() {
    return this.subtype === 'all'
  }

  get archives() {
    return this.isAll
        ? archiveStore.archiveData.filter(this.predicateAll)
        : archiveStore.archiveData.filter(this.predicatePersonal)
  }

  get archiveItems() {
    return this.archives.map((item) => {
      return {...item, checked: false}
    }).sort((a, b) => {
      const atime = a.createdDate && a.createdDate.toMillis()
      const btime = b.createdDate && b.createdDate.toMillis()
      return atime === btime ? 0 : atime > btime ? -1 : 1
    })
  }

  get emptyArchive() {
    return !this.archives.length
  }

  get selectedArchive() {
    return archiveStore.selectedArchive
  }

  get isGroupChat() {
    return this.selectedArchive?.subtype > 1;
  }

  get imageUrl() {
    if (!this.selectedArchive) {
      return null
    }
    const customer = this.selectedArchive.customer;
    const photoUrl = customer && customer.photoUrl;
    return photoUrl && photoUrl.normal
  }

  get imagesUrls() {
    if (!this.selectedArchive) {
      return null
    }
    return Object.values(this.selectedArchive.members)
        .filter((item: any) => item && !!item.photoUrl)
        .map((item: any) => item.photoUrl.normal);
  }

  get imagesUrlsCnt() {
    if (!this.selectedArchive) {
      return false
    }

    let imageUrl = Object.values(this.selectedArchive.members)
        .filter((item: any) => item && !!item.photoUrl)
        .map((item: any) => item.photoUrl.normal);
    return imageUrl.length > 0;
  }

  get hasTitle() {
    return !!this.selectedArchive?.title
  }

  get archiveTitle() {
    if (this.hasTitle) {
      return this.selectedArchive.title;
    }

    if(this.isGroupChat || (this.selectedArchive != null && this.selectedArchive.memberIDs.length > 2)){
      const custId = this.selectedArchive.customer?.id
      let titleText = this.selectedArchive.customer?.name || ''      
      
      //#BAW-983 - customer name + other associates first name    
      if(titleText){
        const memberUids = Object.keys(this.selectedArchive?.members || {}).sort();
        memberUids.forEach(uid => {
          const member = this.selectedArchive.members[uid];        
          if (member.uid != custId) {          
            titleText =  titleText + ', ' + getFirstName(member.name);
          }        
        });
      }else{
        return Object.values(this.selectedArchive.members).map((item: any) => item.name).sort().join(', ')
      }
      return titleText  
    }

    return this.selectedArchive?.customer?.name
  }

  get checkedForExport() {
    return this.archiveItems.filter((item) => item.checked)
  }

  get exporting() {
    return archiveStore.exporting
  }

  get exportError() {
    return archiveStore.exportError
  }

  get exportResult() {
    return archiveStore.exportResult
  }

  get caseMessages() {
    return archiveStore.caseMessages
  }

  get isEmpty() {
    return !this.srcArchive?.length
  }

  isForwardMessage(message: any) {
    const app: any = this
    return message.data?.type === app.$consts.MESSAGE_TYPE_FORWARD
  }

  isForwardedImage(message: any) {
    const app: any = this
    return message.data?.forwardedMessage?.type === app.$consts.MESSAGE_TYPE_IMAGE
  }

  isStandardMessage(message: any) {
    const app: any = this
    return message.data?.type === app.$consts.MESSAGE_TYPE_STANDARD || message.data?.sender?.type === 0
  }

  isRequestDataMessage(message: any) {
    const app: any = this
    return message.data?.type === app.$consts.MESSAGE_TYPE_REQUEST_PERSONAL_DATA
  }

  isRequestDataResultMessage(message: any) {
    const app: any = this
    return message.data?.type === app.$consts.MESSAGE_TYPE_PERSONA_DATA_RESULT
  }

  isImageMessage(message: any) {
    const app: any = this
    return message.data?.type === app.$consts.MESSAGE_TYPE_IMAGE
  }

  isAddMemberMessage(message: any) {
    const app: any = this
    return message.data?.type === app.$consts.MESSAGE_TYPE_ADD_PERSON_TO_GROUP
  }

  isReplyMessage(message: any) {
    const app: any = this
    return message.data?.type === app.$consts.MESSAGE_TYPE_REPLY_TO
  }

  isLocationMessage(message: any) {
    const app: any = this
    return message.data?.type === app.$consts.MESSAGE_TYPE_LOCATION
  }

  isBusinessCardMessage(message: any) {
    const app: any = this
    return message.data?.type === app.$consts.MESSAGE_TYPE_BUSINESS_CARD
  }

  @Watch('archiveItems')
  onArchiveItemsChanged(after, before) {
    this.srcArchive = Object.assign([], after);
  }

  @Watch('exportMode')
  onExportModeChanged(after: boolean, before: boolean) {
    if (!after) {
      this.srcArchive.forEach((item) => item.checked = false)
      this.resetDateFilter();
    }
  }

  @Watch('exportResult')
  onExportResultChanged(after, before) {
    if (after !== before) {
      this.dialogExportResult = true
      this.onExportArchiveWatch();     
    }
  }

  @Watch('exportError')
  onErrorChanged(after, before) {
    if (after !== before) {
      this.showIssue(after)
    }
  }
 
  @Watch('toEmail')
  onChangeEmail() {
    const archiveInfo: any = this.$refs.archiveInfo;
    this.disableExport = !archiveInfo.validate()
  }
  

  onCaseNotes() {
    this.chatInfoWindowKey = 'caseNotes'
    this.drawerChatInfo = true
  }

  onChatInfo() {
    this.chatInfoWindowKey = 'info'
    this.drawerChatInfo = true
  }

  onViewPersonalData() {

  }

  async onShareArchive() {
    try {
      const shortLink = await archiveStore.shareArchive();
      await copyTextToClipboard(shortLink);
      this.showInfo('Archive link copied to clipboard');
    } catch (err: any) {
      this.showIssue(err.message);
    }
  }

  showAvatar(index: number, message: any) {
    const prevMessage = this.caseMessages[index - 1];
    const prevSenderUid = prevMessage && prevMessage.data?.sender?.uid;
    const nextMessage = this.caseMessages[index + 1];
    const nextSenderUid = nextMessage && nextMessage.data?.sender?.uid;
    if (!prevSenderUid && !nextSenderUid) {
      return true;
    } else {
      return nextSenderUid !== message.data?.sender?.uid;
    }
  }

  messageKind(index, messages) {
    const prevIndex = index - 1
    const nextIndex = index + 1

    const prevMsg = messages[prevIndex]
    const nextMsg = messages[nextIndex]

    const currentUid = messages[index].data.sender.uid;
    const prevUid = prevMsg && prevMsg.data.sender.uid;
    const nextUid = nextMsg && nextMsg.data.sender.uid;

    return [currentUid === prevUid, currentUid === nextUid];
  }

  search(term) {
    if (!term) {
      this.srcArchive = Object.assign([], this.archiveItems);
      return;
    }
    const searchString = term.toLowerCase().replace('  ', ' ')
    this.srcArchive = this.archiveItems.filter(
        (item) => item.customer?.name?.toLowerCase()?.replace('  ', ' ').includes(searchString)
            || item.associate?.name?.toLowerCase()?.replace('  ', ' ').includes(searchString));
  }

  selected(id): boolean {
    return this.selectedArchive?.id === id;
  }

  onArchiveItemSelected(item: any) {
    if (!this.selected(item.id)) {
      this.$router.push({name: this.$route.name!, params: {archiveId: item.id}, query: this.$route.query});
    }
  }
  
  
  get titleExporting(){
    let title = 'Export daily report';
    let casesText = 'cases';

    if(this.checkedForExport  && this.checkedForExport.length > 0){
      if(this.checkedForExport.length === 1){
        casesText = 'case';
      }
      title = 'Export ' + this.checkedForExport.length + ' ' + casesText;
    }else if(this.selectedDate){     
      title = 'Export a report';
    }
    return title;
  }

  get titleDialog(){
    let title = 'Export daily report';
    let casesText = 'cases';

    if(this.checkedForExport  && this.checkedForExport.length > 0){
      if(this.checkedForExport.length === 1){
        casesText = 'case';
      }
      title = 'Export ' + this.checkedForExport.length + ' ' + casesText;
    }else{     
      title = 'Export cases for ' + this.getSelectedDate();
    }
    return title;
  }

  get titleCalendar(){   
    return 'Select the report date';
  }

  trimTitle(str) {
      if (str.length > 100) {
      return str.substr(0, 100) + '...' + str.substr(str.length-50, str.length);
    }
    return str;
  }

  getSelectedDate(){
    const sTemp = this.startDate;
    const eTemp = this.endDate;

    const sDate = dateToMDY(sTemp);
    const eDate = dateToMDY(eTemp);
    let resut = sDate;

    if(sDate != eDate){
      resut = sDate + ' - ' + eDate;
    }
    return resut;
  }

  dayFormat(date) {
    const index = new Date(date).getDay()
    return this.daysOfWeek[index]
  }

  allowedDates(val) {
    const date = new Date(val)
    date.setHours(0, 0, 0)

    const now = new Date()
    now.setHours(0, 0, 0)

    return date < now;     
  }

  resetDateFilter(){
    this.startDate = new Date();       
    this.endDate = new Date(); 
    this.startDate.setHours(0,0,0,0);
    this.endDate.setHours(23,59,59,999);
    
    this.dates = [(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substring(0, 10)] 
    this.selectedDate = '';
  }

  onShowCalendar(){
    this.dialogCalendar = true;
    this.srcArchive.forEach((item) => item.checked = false)
    this.resetDateFilter();
  }

  onCloseCalendar() {
    this.$emit('close-dialog');
    this.dialogCalendar = false;
    this.resetDateFilter();
  } 

  onCalendarConfirm(){
    this.dialogCalendar = false;    
    this.startDate = new Date();       
    this.endDate = new Date();     

    //get date
    if(this.dates.length > 0){      
      this.startDate = new Date(this.dates[0]);

      if(this.dates.length > 1)
        this.endDate = new Date(this.dates[1]);
      else    
        this.endDate = new Date(this.dates[0]);  
    }

    //compare
    if(this.startDate > this.endDate){
      const tmpStart = this.startDate; 
      const tmpEnd = this.endDate; 
      this.startDate = tmpEnd;
      this.endDate = tmpStart;
    }

    this.startDate.setHours(0,0,0,0);
    this.endDate.setHours(23,59,59,999);
    this.selectedDate = this.getSelectedDate();    
  }

  async onShowExportingDialog() {
    this.dialogArchive = true;   
    this.dialogExportResult = true 
  }
  
  async onExportArchive() {

    let ids!: string[];  

    //get ids
    if(this.checkedForExport.length){
      //selection
      ids = this.checkedForExport.map((item) => item.id);      
    }else{   

      //date range
      let dateFilter = this.archiveItems.filter((item) => 
        item.createdDate.toMillis() >= this.startDate.getTime() && 
        item.createdDate.toMillis() <= this.endDate.getTime()
      );

      if(dateFilter && dateFilter.length > 0){
        ids = dateFilter.map((item) => item.id);
      }
    } 

    //export
    if(ids && ids.length > 0){
      if(this.exportType == 0){  
        await archiveStore.exportArchive(ids)        
      }else{
        await this.sendEmail(ids);
      }      
    }else{
      this.showIssue('No results found');
    }

    this.dialogArchive = false;
    this.exportMode = false
  }

  onExportArchiveWatch(){
    try{      

      let result, pdfLink, htmlLink;
      result = this.exportResult  

      if(result) {
        pdfLink = result.pdf
        htmlLink = result.html

         // download
         if(this.exportFileType == 0)
            window.open(pdfLink,'_blank');
          if(this.exportFileType == 1)
            window.open(htmlLink,'_blank');

          this.showSuccess('Export complete successfully');
      }else{
        this.showIssue('Failed to export.');
      } 

    } catch (err) {
      this.showIssue('Failed to export');
    }
  }

  async sendEmail(ids: string[]) {
    const subject = this.titleDialog;
    const mailStatus = await archiveStore.sendArchiveEmail({toEmail: this.toEmail, ids: ids, subject: subject});    
    
    if(mailStatus)
      this.showMailed('Email sent successfully');
    else
      this.showIssue('Failed to send email');
  }

  forceFileDownload(response) {
    const url = window.URL.createObjectURL(new Blob([response.data]))
    const link = document.createElement('a')
    link.href = url
    link.setAttribute('download', '')
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }

  async onDownloadPDF() {
    try {
      const response = await axios({
        method: 'get',
        url: this.exportResult.pdf,
        responseType: 'arraybuffer'
      })
      this.forceFileDownload(response)
    } catch (err) {
      this.showIssue('Failed to download PDF');
    }
    this.dialogExportResult = false
    this.exportMode = false
  }

  async onCopyPDFLink() {
    try {
      await copyTextToClipboard(this.exportResult.pdf);
      this.showInfo('PDF report link has been copied');
    } catch (err) {
      this.showIssue('Failed to copy PDF report link');
    }
    this.dialogExportResult = false
    this.exportMode = false
  }

  async onCopyHTMLLink() {
    try {
      await copyTextToClipboard(this.exportResult.html);
      this.showInfo('HTML report link has been copied');
    } catch (err) {
      this.showIssue('Failed to copy HTML report link');
    }
    this.dialogExportResult = false
    this.exportMode = false
  } 

  unsubscribeAll() {
    unsubscribeSafe([this.unsubscribeCaseNotes, this.unsubscribeCustomerNotes])
  }

  async init(archiveId: string, query: any) {
    await archiveStore.selectArchive({archiveId: archiveId, ...query})
    await archiveStore.loadCaseMessages()
    this.unsubscribeCaseNotes = await chatStore.loadCaseNotes(true)
    this.unsubscribeCustomerNotes = await chatStore.loadCustomerNotes(true);
  }

  initEmpty() {
    const name = this.$route.name
    //const type = this.$route.query.type;
    const subtype = this.$route.query.subtype;
    switch (name) {
      case 'archive':
      case 'profile-archive':
        this.emptyIcon = require('../assets/_empty_states_archive.svg')
        this.emptyTitle = 'No archived cases yet'
        this.emptyDescription = 'Once you close a case,<br>' +
            'it will appear here'
        break
    }
  }

  updated() {
    this.initEmpty()
  }

  created() {
    this.srcArchive = Object.assign([], this.archiveItems);
    this.initEmpty()
  }

  mounted(){
    this.resetDateFilter();
  }
  

  async beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.init(to.params.archiveId, to.query)
    })
  }

  async beforeRouteUpdate(to, from, next) {
    this.unsubscribeAll()
    await this.init(to.params.archiveId, to.query)
    next()
  }

  async beforeRouteLeave(to, from, next) {
    this.unsubscribeAll()
    next()
  }
}
