

































import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import SearchView from '@/components/SearchView.vue';
import InnerChatListItem from '@/components/InnerChatListItem.vue';
import EmptyState from "@/components/EmptyState.vue";
import {Getter} from 'vuex-class';
import {firstWord, twoChars} from '@/utils/helpers';
import {applicationStore} from '@/store/modules/application';
import {profileStore} from '@/store/modules/profile';

@Component({
  name: 'inner-chats-list',
  components: {InnerChatListItem, SearchView, EmptyState}
})
export default class InnerChatsList extends Vue {
  @Prop({default: 240}) width?: number | string;
  @Prop({default: 240}) maxWidth?: number | string;
  @Prop({default: []}) items?: any[];
  @Prop({default: false}) showCreateChat?: boolean;

  @Getter selectedTextSession;
  @Getter selectedChatId;

  srcItems?: any[] = [];
  emptyIcon: string = ''
  emptyTitle: string = ''
  emptyDescription: string = ''
  emptyArrow: boolean = false

  @Watch('items') onItemsChanged(value, oldValue) {
    this.srcItems = Object.assign([], value);
  }

  get t2bUser() {
    return profileStore.t2bUser
  }

  get isAdmin() {
    return applicationStore.isAdmin
  }

  get isEmpty() {
    return !this.srcItems?.length
  }

  get style() {
    return {
      width: `${this.width}px`,
      maxWidth: `${this.maxWidth}px`
    };
  }

  selected(id): boolean {
    return this.selectedChatId === id;
  }

  search(term) {
    if (!term) {
      this.srcItems = this.items;
      return;
    }
    const searchString = term.toLowerCase();
    this.srcItems = this.items!!.filter((value) =>
        value.from.name.toLowerCase().includes(searchString)
        || value.to.name.toLowerCase().includes(searchString)
        || (!!value.title && value.title.toLowerCase().includes(searchString)));
  }

  onItemSelected(value) {
    if (!this.selected(value.id)) {
      this.$emit('item-selected', value.id);
    }
  }

  isGroup(item): boolean {
    return item.subtype !== 1;
  }

  abbreviation(conversation) {
    return twoChars(this.chatItemTitle(conversation));
  }

  chatItemTitle(conversation) {
    if (!conversation) {
      return '';
    }

    //if chat title is given used that title as chat userpic letter 
    if(conversation.title != null) {
      return conversation.title
    }
    
    switch (conversation.subtype) {
      case 1:
      case 2: {
        const fromId = conversation.from && (conversation.from.uid || conversation.from.id);
        return this.t2bUser!.id === fromId ? conversation.to && conversation.to.name : conversation.from && conversation.from.name;
      }
      case 3:
        return Object.values(conversation.members).map((item: any) => firstWord(item.name)).sort().toString();
      default:
    }
  }

  onlineStatus(conversation): boolean {
    const fromId = conversation.from && (conversation.from.uid || conversation.from.id);
    const member = conversation.members && conversation.members[fromId];
    return member && member.status && member.status.online;
  }

  initEmpty() {
    const name = this.$route.name
    const subtype = this.$route.query.subtype;
    console.log('initEmpty', name)
    switch (name) {
      case 'inner': case 'inner-chat':
        this.emptyIcon = require('../assets/_empty_states_chat.svg')
        if (subtype === 'personal') {
          this.emptyTitle = 'No internal chats yet'
          this.emptyDescription = 'Start a new conversation with your<br>' +
              'colleague by selecting the + at the top<br>' +
              'and choosing a contact to message'
          this.emptyArrow = true
        } else {
          this.emptyTitle = 'No chats yet'
          this.emptyDescription = 'Your team\'s internal chats<br>' +
              'will appear here'
          this.emptyArrow = false
        }
        break
    }
  }

  updated() {
    this.initEmpty()
  }

  created() {
    this.srcItems = Object.assign([], this.items);
    if (this.selectedChatId) {
      this.$emit('chat-selected', this.selectedChatId);
    }
    this.initEmpty()
  }

};
