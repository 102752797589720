











































import {Component, Prop, Vue} from 'vue-property-decorator';
import BaseDialog from '@/components/BaseDialog.vue';
import ContactsList from '@/components/ContactsList.vue';
import {BusinessContact, messageType, senderType} from '@/domain/model/types';
import {Action, Getter} from 'vuex-class';
import {profileStore} from '@/store/modules/profile';
import {chatStore} from "@/store/modules/chat/ChatStore";

import { chat, newChatMessage } from '@/data/firebase';
import { setDoc, arrayUnion, Timestamp, updateDoc } from 'firebase/firestore';
import Notifications from '../mixins/Notifications';

@Component({
  name: 'dialog-add-chat-members',
  components: {ContactsList, BaseDialog},
})
export default class DialogAddChatMembers extends Notifications {
  @Prop() value!: boolean;
  @Prop({default: false}) create!: boolean;
  selectedContacts: BusinessContact[] = []
  chatName: string = ''
  userId?: string | null = profileStore.t2bUser?.id

  @Getter chatsBusy
  @Getter chatsError
  @Getter selectedTextSession

  get show() {
      return this.value;
  }

  set show(value: boolean) {
      this.$emit('input', value);
  }

  get titleChanged() {
    const title: string = this.selectedTextSession.title ? this.selectedTextSession.title : ''
    return title !== this.chatName
  }

  get title() {
      return this.create ? 'Create group' : 'Add members'
  }

  get filterIDs() {
      return (this.selectedTextSession && this.selectedTextSession.memberIDs) || []
  }

  get sender() {
      if (this.selectedTextSession.type === 4) { // active chat
          if (this.selectedTextSession.associate.id === this.userId) {
              return {
                  contactId: this.selectedTextSession.associate.id,
                  contactName: this.selectedTextSession.associate.name
              }
          }
      }
      const from = this.selectedTextSession.from;
      if (from.uid === this.userId) {
          return {
              contactId: from.id,
              contactName: from.name
          }
      }
      const to = this.selectedTextSession.to;
      if (to.uid === this.userId) {
          return {
              contactId: to.id,
              contactName: to.name
          }
      }
      const member = this.selectedTextSession.members[this.userId!];
      if (!!member) {
          return {
              contactId: member.id,
              contactName: member.name
          }
      }
      return null
  }

  @Action addChatMembers

  onContactsSelected(contacts: BusinessContact[]) {
    this.selectedContacts = contacts;
  }

  async onAddChatMembers() {

    var customers = this.selectedContacts.filter((item) => item.isCustomer == true)
    var admins = this.selectedContacts.filter((item) => item.isCustomer == false || item.isCustomer == undefined)

    const data = {
        sender: this.sender,
        contactIds: admins.map((item) => item.id),
        title: this.chatName
    }
    if (data.contactIds.length > 0 || customers.length > 0) {
      const addedMember = await this.addChatMembers(data);
      const addedCustomer = await this.onAddCustomers(customers)

      if (!!addedMember && !!addedCustomer) {
        this.show = false
      }
    } else {
      await chatStore.changeChatTitle({chatId: this.selectedTextSession.id, title: this.chatName})
      this.show = false
    }
  }

  async onAddCustomers(data){
    if(data.length == 0){
        return true
    }
    try {
      return await Promise.all(data.map(async (customer) => {
        if(this.selectedTextSession.memberIDs.includes(customer.id)){
            this.showIssue('Contact Already in Group');
            return
        }
        
        await setDoc(chat(this.selectedTextSession.id), {memberIDs: arrayUnion(customer.id)}, {merge: true}).then((res) =>{
            setDoc(chat(this.selectedTextSession.id), { members : {
                [customer.id]: {
                    description: "Customer",
                    id: customer.id,
                    name: customer.fullName,
                    status: customer.status,
                    type: 2,
                    uid: customer.id
                }
            }}, { merge: true}).then((res) =>{
                //add system messgae
                let associate = this.selectedTextSession.associate
                setDoc(newChatMessage(this.selectedTextSession.id), {
                    text: associate.fullName + ' added ' + customer.fullName + ' to this chat',
                    sender: {name: associate.fullName, uid: this.userId, contactId: this.userId, type: senderType.SYSTEM},
                    recipient: { uid: customer.id, contactId: customer.id, name: customer.fullName, type: "2" },
                    textSessionId: this.selectedTextSession.id,
                    type: messageType.ADD_PERSON_TO_GROUP,
                    createdDate: Timestamp.now(),
                    memberIDs: this.selectedTextSession.memberIDs
                    }, {merge: true})
            })
        });
      }));
      
    } catch (error) {
      console.error(error);
      return error
    }

  }

  created() {
    this.chatName = this.selectedTextSession.title
  }
}
